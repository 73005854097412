const api = "https://sap.intelligence.amobee.com";
const long_timeout_api = "https://lsap.intelligence.amobee.com"; // Temp fix to allow 2GB files upload for first-party upload - lsap allow more than 5min request

module.exports = function () {
	return {
        FIRST_PARTY_API: `${api}/first-party`,
        USER_MGMT_API: `${api}/user-mgmt`,
        AUTHENTICATION_API: `${api}/authentication`,
        SNAPSHOT_API: `${api}/snapshot`,
        TRENDLINE_API: `${api}/herodotus-api`,
        INTERESTS_API: `${api}/herodotus-api`,
        SEARCH_API: `${api}/abi-content-search`,
        PROXY_TOWER_API: `${api}/proxy-tower`,
        AUDIENCE_PROFILER_API: `https://audience-profiler-ui.intelligence.amobee.com`,
        LOGGING_API: `${api}/user-mgmt`,
        KEYWORDS_SUGGESTIONS_API: `${api}/kw-suggest`,
        FIRST_PARTY_UPLOADER: `${long_timeout_api}/first-party-uploader`,
        ACTIVATION_API: `${api}/activation`
    };
};