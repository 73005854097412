import config from '../../infra/config';

const HOST = config.FIRST_PARTY_API;
const HEADERS = { 'Content-Type': 'application/json' };

export default {
  getSegmentSizes: (payload) => ({
    method: 'post',
    payload,
    headers: HEADERS,
    url: `${HOST}/segment/sizes`,
  }),
};
