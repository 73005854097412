let conf;
const apiGateway = require('infra/api-gateway');
let host = window.location.host;
// This value will be changed on final Kong deployment - changed to what?
const api_gw_prefix = "sap";
const domain = "intelligence.amobee.com";

const prod_environments = ["intelligence.amobee.com", "next.intelligence.amobee.com"]

if (IS_PRODUCTION) {
    conf = prod_environments.includes(host) ? apiGateway() : integrationLocation();
} else {
    conf = require('devConfig');
}

function getLocation(prefix, use_gateway=true){
    return IS_GATEWAY && use_gateway ? `https://${api_gw_prefix}.${host.split('.')[0]}.${domain}/${prefix}` : `//${prefix}.${host}`;
}

function integrationLocation(){
	return {
        FIRST_PARTY_API: getLocation("first-party"),
        USER_MGMT_API: getLocation("user-mgmt"),
        AUTHENTICATION_API: getLocation("authentication"),
        SNAPSHOT_API: getLocation("snapshot"),
        TRENDLINE_API: getLocation("herodotus-api"),
        INTERESTS_API: getLocation("herodotus-api"),
        SEARCH_API: getLocation("abi-content-search"),
        PROXY_TOWER_API: getLocation("proxy-tower"),
        AUDIENCE_PROFILER_API: getLocation("audience-profiler-ui", false),
        LOGGING_API: getLocation("user-mgmt"),
        KEYWORDS_SUGGESTIONS_API: getLocation("kw-suggest"),
        FIRST_PARTY_UPLOADER: getLocation("first-party-uploader"),
        ACTIVATION_API: getLocation("activation")
    };
}
export default conf;
