import { react2angular } from 'react2angular';
import { getSegmentValuesSummary, isSmartTvChannel, SEGMENT_ROW_MAP }
  from '../../data/audience-segment-builder-helper';
import { getAudienceSize, getSegmentInterestsData, getSegmentParams, isAudienceSizeTooSmall}
  from '../../react/services/AudienceInsightsService';
import { QUERY_NAMES } from 'react/services/AudienceServiceHelper';
import AudienceExplorerTopBar
    from 'react/components/apps/audience/AudienceExplorer/AudienceExplorerTopBar/AudienceExplorerTopBar';
import AudienceExplorerSecondaryBar
    from 'react/components/apps/audience/AudienceExplorer/AudienceExplorerSecondaryBar/AudienceExplorerSecondaryBar';
import { checkNameSensitivity, checkSensitivity } from 'react/utils/sensitivityUtils';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import { updateShowShortSpinner } from '../../react/redux/slices/spinnerSlice';

const audienceExplorerModule = angular.module(__filename, [
    require('../../widgets/audience-demographics-widget/audience-demographics-widget').name,
    require('../../widgets/audience-tv-widget/audience-tv-widget').name,
    require('../../widgets/audience-table-view-widget/audience-table-view-widget').name,
    require('../../widgets/audience-interests-widget/audience-interests-widget').name,
    require('../../widgets/audience-linkedin-interests-widget/audience-linkedin-interests-widget').name,
    require("../../common/am-tabs.drv/am-tabs.drv").name,
    require("../../data/audience-explorer-export-service").name
]);

audienceExplorerModule.component('audienceExplorerTopBar', react2angular(AudienceExplorerTopBar, ['audienceName', 'audienceInfoTooltipContent']))
                      .component('audienceExplorerSecondaryBar', react2angular(AudienceExplorerSecondaryBar, ['onExportClick', 'tabs', 'selectedTab', 'onTabSelect', 'onRefineAudienceClick']));

audienceExplorerModule.stateConfig = [{
    name: "audience-explorer",
    url: "/audience-explorer",
    template: require("./audience-explorer.html"),
    display: "Audience",
    controller: audienceExplorerController,
    data: {
      permissions: ['audience']
    }
}];


audienceExplorerController.$inject = ["$scope", "$state", "abiPermissions", "context", "filtersPartition",
    'audienceMgmt', 'notificator', 'TargetsCommon', '$q', 'audienceExplorerExportService', 'segmentInterestExportService',
    '$ngRedux', '$rootScope'];
function audienceExplorerController($scope, $state, abiPermissions, context, filtersPartition, audienceMgmt,
                                    notificator, TargetsCommon, $q, audienceExplorerExportService,
                                    segmentInterestExportService, $ngRedux, $rootScope) {

    const channel = context.current.audience_app.current_channel.value;
    let showTvTab;
    $scope.hasPermission = abiPermissions.hasPermission;
    $scope.channel = channel;
    $scope.audienceName = (context.current.audience_app[channel] || {}).audience_name;
    $scope.exportControl = {};
    $scope.TABS_NAMES = {wiki:'wiki', tv: 'tv'};

    // each channel support other types of data
    if(channel === "linkedin")
        $scope.tabs = [{label: "Interests", value: "linkedin_interests"}];
    else {
        const SG_CHANNELS = ['snbb', 'data_spark', 'sg_bidstream', 'bidstream'];
        const debugUser = $scope.$root.user.userType === 'debug';
        const isTvChannelPartialData = channel === 'tivo' && !abiPermissions.hasPermission('audience tv channel - complete data');

        showTvTab = !SG_CHANNELS.includes(channel) && channel !== 'au_telco' && channel !== 'articles' &&
                          abiPermissions.hasPermission(['tv']) && !isTvChannelPartialData;
        const showInterestsTab = abiPermissions.hasPermission(['interests']) &&
                                 !isTvChannelPartialData;
        const hasTvaLitePermission = isSmartTvChannel(channel) && abiPermissions.hasPermission('smart tva lite');
        const tvaLiteTooltip = 'Contact your Amobee Account Manager to learn more about advanced insights-to-activation.';

        $scope.tabs = _.compact([
            abiPermissions.hasPermission(['demographics']) && {label: "Demographics", value: "demographics"},
            debugUser && {label: "Websites", value: "domain"},
            debugUser && {label: "Keywords", value: "keywords"},
            debugUser && {label: "Consumed Phrases", value: "web"},
            showInterestsTab && {label: "Interests", value: "wiki", ...(hasTvaLitePermission && {isDisabled: true, tooltip: tvaLiteTooltip})},
            showTvTab && {label: "TV Shows", value: "tv", ...(hasTvaLitePermission && {isDisabled: true, tooltip: tvaLiteTooltip})}
        ]);
    }

    $scope.SEGMENT_MAP = SEGMENT_ROW_MAP;
    $scope.selectedTab = $scope.tabs[0];

    $scope.handleActivatingTarget = ()=> {
        const audience = context.current.audience_app[$scope.channel];
        const segment = audience.audience_segment;
        const channel = $scope.channel;
        TargetsCommon.openAudienceContextualTarget({
            segment,
            channel,
            phrasesPromise: ()=>getSegmentInterestsData(audience.audience_segment, QUERY_NAMES.phrases, $scope.channel),
            query: getSegmentParams(segment, QUERY_NAMES[$scope.tab], channel),
            audience_name: context.current.audience_app[$scope.channel].audience_name,
            $scope,
            program_id: context.current.p_id
        });
    };

    function handleAudienceSensitivity(audience, props) {
        return new Promise(async (resolve, reject) => {
            try {
                const {
                    reviewSensitivityHandler,
                    sensitivityData,
                } = await checkSensitivity(audience, (newSegments) => {
                    if (newSegments) audience.segments = newSegments;

                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ isDirty: !_.isEmpty(newSegments) });
                });

                if (_.isEmpty(sensitivityData)) return resolve({ isDirty: false });

                const cancelHandler = () => {
                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                };
                $scope.reviewSensitivity = {
                    isOpen: true,
                    data: sensitivityData,
                    onCancel: cancelHandler,
                    onSubmit: reviewSensitivityHandler,
                    ...props,
                };
                $scope.$apply();
            } catch (e) {
                reject(e);
            }
        });
    }

    let saveInProcess = false;
    async function saveAudience(props) {
        if (saveInProcess) return { canceled: true };

        saveInProcess = true;
        try {
            const { channel } = $scope;
            const audienceApp = context.current.audience_app[channel];
            const {
                audience_id,
                audience_name,
                audience_segment: segments,
                audience_advancedSegmentation: advancedSegmentation,
                is_audience_dirty,
            } = audienceApp;
            const audience = {
                id: audience_id,
                name: audience_name,
                segments: _.cloneDeep(segments),
                advancedSegmentation,
                channel,
            };
            const {canceled, isDirty} = isSmartTvChannel(channel)
              ? await handleAudienceSensitivity(audience, props)
              : { canceled: false, isDirty: false};
            if (canceled) return { canceled: true };

            // skip saving of audience if there are no segments to save (because removing the sensitive ones cleared everything)
            audienceApp.audience_segment = audience.segments;
            if (_.isEmpty(audience.segments)) return { canceled: true };

            // skip saving of audience if it is not changed
            if (!isDirty && !is_audience_dirty) return { canceled: false };

            const data = {
                name: audience_name,
                segments: _.cloneDeep(segments),
                advancedSegmentation,
                channel,
            };

            const res = await (audience_id ? audienceMgmt.update(audience_id, {data}) : audienceMgmt.create({data}));
            audienceApp.audience_id = res.id;
            const msg = `The audience "${audience_name}" was saved successfully`;
            notificator.success({body: msg});
            await audienceApp.finalizeSave(res);
            return { canceled: false };
        } catch (e) {
            const msg = 'Failed saving audience';
            notificator.error({ body: msg });
            return { canceled: true };
        } finally {
            saveInProcess = false;
        }
    }

    async function checkDemographicsData(segment, channel, userId, {isBidstream = false, minimalSize = 150, filterBidstreamDomains = true}) {
        const options = {
            channel,
            userId,
            isBidstream,
            minimalSize,
            filterBidstreamDomains,
        };
        const size = await getAudienceSize(segment, options);
        if (isAudienceSizeTooSmall(size)) {
            notificator.error('Your audience is too narrow and cannot be activated. Please refine your audience.');
            return false;
        }
        return true;
    }

    async function checkName(id, name) {
        if (!name) return { isValid: false };

        const { channel } = $scope;
        const { current: { audience_app: { [channel]: audienceApp} } } = context;
        const audiences = await audienceApp.getProgramAudiencePromise;
        const nameExists = !_.isEmpty(audiences) && !!audiences.find((a) => a.id !== id && a.name.toLowerCase() === name.toLowerCase());
        if (nameExists) return {
            error: `The name "${name}" already exists. Please choose a different name.`,
            isValid: false,
        };

        const {passed, text} = await checkNameSensitivity(name);
        if (!passed) return { error: text, isValid: false };

        return { isValid: true };
    }

    function getAudienceName(id, origName) {
        return new Promise(async (resolve) => {
            const { isValid, error } = await checkName(id, origName);
            if (isValid) {
                return resolve({ name: origName });
            }

            $scope.audienceNameProps = {
                isOpen: true,
                origName,
                origErrorMessage: error,
                checkName: (name) => checkName(id, name),
                onCancel() {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                },
                onSubmit(name) {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ name });
                },
            };
            $scope.$apply();
        });
    }

    $scope.handleActivatingAudience = async () => {
        const { channel } = $scope;
        const { current: { audience_app: { [channel]: audienceApp} } } = context;
        const { audience_id, audience_name } = audienceApp;
        const {name, canceled: nameCanceled} = await getAudienceName(audience_id, audience_name);
        const isBidstream = channel === 'articles';
        const minimalSize = 150;
        const filterBidstreamDomains = channel === 'articles';
        if (nameCanceled) return { canceled: true };

        audienceApp.audience_name = name;
        const { canceled } = await saveAudience({
            modalTitle: 'Review before activating',
            keepPhrasesText: 'Activate Audience',
            removePhrasesText: 'Remove & Activate',
        });
        if (canceled) return { canceled: true };

        const { $root: { user: { id: userId } } } = $scope;
        const { audience_segment: currSegments } = audienceApp;
        const demographicsDataOk = await checkDemographicsData(currSegments, channel, userId, {isBidstream, minimalSize, filterBidstreamDomains});
        if (!demographicsDataOk) return { canceled: true };

        return {
            audienceId: audienceApp.audience_id,
            audienceName: audienceApp.audience_name,
        };
    };

    $scope.navToPrevTab = () => {
      $scope.selectedTab = $scope.prevTab || $scope.tabs[0];
      $scope.$digest();
    };

    $scope.navToAudienceBuilder = () => {
        $state.go('audience-builder');
    };

    $scope.refineAudienceClick = () => {
        MixpanelAudience.trackRefine($scope.selectedTab.label.toLowerCase(), $scope.channel);
        MixpanelAudience.trackAudienceExplorerTopicClick('All Interests', 'audience-explorer', $scope.channel);
        $scope.navToAudienceBuilder();
    };

    $scope.onSelectTab = function (selectedTab) {
        $scope.prevTab = $scope.selectedTab;
        $scope.selectedTab = selectedTab;

        $scope.$digest();

        MixpanelAudience.trackPageView(selectedTab.label, $scope.channel);
    };

    $scope.audienceInfoTooltipContent = function() {
        const channel = (context.current.audience_app || {}).current_channel;
        const audienceSegment = (context.current.audience_app[channel.value] || {}).audience_segment || [];
        const divWrapper = (content) => `<div style="line-height: 19px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${content}</div>`;
        let tooltipContent = channel.label === 'All' ? '' : divWrapper(`<strong>Channel:</strong> ${channel.label}`);
        audienceSegment.forEach((segment) => {
            const segmentTypeLabel = SEGMENT_ROW_MAP[segment['type']]['label'];
            tooltipContent += divWrapper(`<strong>${segmentTypeLabel}:</strong> ${getSegmentValuesSummary(segment, filtersPartition, true)}`);
        });

        return tooltipContent;
    };

    $scope.isTabAvailable = function(tabName) {
        return !!_.find($scope.tabs, {value: tabName});
    };

    $scope.exportControl.export = ()=> {
        $ngRedux.dispatch(updateShowShortSpinner(true));
        const tvProm = $scope.isTabAvailable($scope.TABS_NAMES.tv) && $scope.exportControl.getAudienceTvExportDependencies();
        const interestsProm = $scope.isTabAvailable($scope.TABS_NAMES.wiki) && $scope.exportControl.getAudienceInterestsExportDependencies();

        $q.all([tvProm, interestsProm])
          .then(([audienceTv, audienceInterests]) => {
              $ngRedux.dispatch(updateShowShortSpinner(false));
              let [topics, websites, phrases, searches, interestsDemographicsData] = audienceInterests || [];
              const [data, tvDemographicsData, filteredByNetworks] = audienceTv || [];
              const demographicsData = tvDemographicsData || interestsDemographicsData;

              topics = _.orderBy(topics, 'interest-portion', 'desc');
              const audienceSegment = (context.current.audience_app[channel] || {}).audience_segment || [];

              const isDebugUser = $rootScope.user.userType === 'debug';
              const shouldShowNetwork = ['smart_tv_inscape', 'tivo', 'hisense'].includes(channel) || (isDebugUser && channel === 'articles');

              const audienceInterestExportData = {topics, websites, phrases, searches};
              const tvShowsExportData = {data, withNetworks: shouldShowNetwork, filteredByNetworks};
              const mutualExportData = {segment: audienceSegment, demographicsData, channel: $scope.channel};
              let excel = audienceExplorerExportService.exportToExcel(tvShowsExportData, audienceInterestExportData, mutualExportData, showTvTab);
              segmentInterestExportService.excelDownloader(excel);
          })
          .catch(()=> {
              $ngRedux.dispatch(updateShowShortSpinner(false));
        });
    }
}

module.exports = audienceExplorerModule;
